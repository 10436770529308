<div class="login-container">
    <div class="login" [ngClass]="{'mobile': sharedDataService.isHandset$ | async}">
        <h3>Willkommen in der <span class="no-wrap">Neo App</span></h3>

        <div class="circle">
            <img src="./assets/images/baby.png" alt="">
        </div>

        <p>Um Zugang zu der Galerie zu bekommen, benötigst du das Passwort, welches durch die Eltern des Kindes vergeben wurde.</p>

        <form (ngSubmit)="submitPassword()">
            <mat-form-field appearance="fill">
                <mat-label>Passwort</mat-label>
                <input type="password" matInput [formControl]="passwordFormControl">
                <mat-error *ngIf="passwordFormControl.hasError('passwordInvalid')">
                    Das Passwort ist ungültig.
                </mat-error>
            </mat-form-field>
        </form>

        <button
            mat-raised-button
            color="primary"
            (click)="submitPassword()"
            [disabled]="passwordFormControl.invalid">Einloggen</button>
    </div>
</div>
