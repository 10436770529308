import { Component, ElementRef, Input } from '@angular/core'
import { IMilestone } from 'src/app/data/models/IMilestone'

@Component({
    selector: 'app-milestone',
    templateUrl: './milestone.component.html',
    styleUrls: ['./milestone.component.sass'],
    standalone: false
})
export class MilestoneComponent {
  @Input('milestone') milestone?: IMilestone

  constructor(public hostElement: ElementRef) {}
}
