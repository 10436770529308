import { Component } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { CONSTANTS } from 'src/app/helper/constants'
import { HelperService } from 'src/app/helper/services/helper.service'
import { SharedDataService } from 'src/app/helper/services/shared-data.service'
import { StorageService } from 'src/app/helper/services/storage.service'
import { GalleryService } from 'src/app/networking/gallery.service'

import { validate as uuidValidate } from 'uuid'

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.sass'],
    standalone: false
})
export class LoginComponent {

  public passwordFormControl = new FormControl('', Validators.required)

  constructor(
    public sharedDataService: SharedDataService,
    private galleryService: GalleryService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService,
    private helperService: HelperService
  ) { }

  async submitPassword() {
    if(this.passwordFormControl.value == null)
      return

    let params: Params = this.activatedRoute.snapshot.queryParams
    let galleryKey = params[CONSTANTS.paramGalleryKey]
    if(uuidValidate(galleryKey)) {
      this.storageService.saveData(galleryKey, CONSTANTS.storageKeyGallery)
      this.galleryService.galleryList(galleryKey, this.passwordFormControl.value).then(() => this.router.navigate(['gallery']))  
    }
    else {
      this.helperService.openSnackBar("Keine gültige Galerie. URL überprüfen.")
      return
    }
  }
}
