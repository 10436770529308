<div class="gallery-entry">
    <div class="date-row">
        <hr>
        <p class="date">{{ entry?.date | date: 'longDate' }}</p>
        <hr>
    </div>

    <div class="title">
        <p *ngFor="let milestone of entry?.milestones">Meilenstein: {{ milestone }}</p>
    </div>

    <img *ngIf="entry?.imageUrl" [attr.src]="entry?.imageUrl" loading="lazy">
</div>
