<router-outlet></router-outlet>

<div class="bottom-bar" [ngClass]="{'mobile': sharedDataService.isHandset$ | async}">
  <div class="legal">
    <a href="{{ constants.termsAndConditions }}">AGB</a>
    <a href="{{ constants.imprint }}">Impressum</a>
    <a href="{{ constants.privacyStatement }}">Datenschutzerklärung</a>
    <a href="{{ constants.licenses }}">Lizenzen</a>
  </div>
  <p class="version">| Neo Galerie by aiddevs. Version: {{ version }}</p>
</div>
