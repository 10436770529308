import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-toggle-icon-button',
    templateUrl: './toggle-icon-button.component.html',
    styleUrls: ['./toggle-icon-button.component.sass'],
    standalone: false
})
export class ToggleIconButtonComponent {
  @Input('icon') icon: string = 'image'
  @Input('active') active: boolean = false
  @Input('label') label?: string
}
