export class CONSTANTS {
    private static baseUrl: string = "https://aiddevs.com/"
    static privacyStatement: string = this.baseUrl + "datenschutzerklaerung-software/"
    static imprint: string = this.baseUrl + "impressum/"
    static licenses: string = "/3rdpartylicenses.txt"
    static termsAndConditions: string = this.baseUrl + "agbs/"

    // storageKeys
    static storageKeyGallery = 'galleryKey'
    static storageKeyData = 'galleryData'

    // url params
    static paramGalleryKey = 'gallery_key'
}
