import { Injectable } from '@angular/core'
import { IGalleryEntry } from '../data/models/IGalleryEntry'
import { IMilestone } from '../data/models/IMilestone'

import { NetworkingService } from './networking.service'

import { v4 as uuidv4 } from 'uuid'
import { API } from '../data/api'
import { HttpHeaders } from '@angular/common/http'
import { StorageService } from '../helper/services/storage.service'

import { environment } from '../../environments/environment'
import { CONSTANTS } from '../helper/constants'

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  data: Array<IGalleryEntry> = this.storageService.loadData(CONSTANTS.storageKeyData)

  constructor(
    private networkService: NetworkingService,
    private storageService: StorageService
  ) { }

  public async getGallery(): Promise<Array<IGalleryEntry>> {
    return Promise.resolve(this.data)
  }

  public async getMilestones(): Promise<Array<IMilestone>> {
    let milestones: Array<IMilestone> = []
    this.data.forEach(entry => {
      entry.milestones.forEach(milestone => {
        milestones.push({
          id: entry.id,
          date: entry.date,
          milestone: milestone
        })
      })
    })
    return Promise.resolve(milestones)
  }

  async galleryList(galleryKey: string, password: string): Promise<any> {
    let result = await new Promise<any>((resolve) => {
      this.networkService.getRequest(
        API.galleryList(galleryKey),
        new HttpHeaders().set("Password", password),
      ).subscribe((response) => resolve(response))
    })

    this.data = []
    result.forEach((entry: any) => {
      let milestones: string[] = []
      entry.milestones.forEach((milestone: any) => {
        milestones.push(milestone.title)
      })

      this.data.push({
        id: uuidv4(),
        date: new Date(entry.created_at),
        milestones: milestones,
        imageUrl: entry.media_key ? `${environment.url}/${environment.backendVersion}/${API.mediaDownload(entry.media_key)}` : undefined
      })
    })

    this.storageService.saveData(this.data, CONSTANTS.storageKeyData)
    return new Promise<boolean>((resolve) => resolve(true))
  }

  async logout(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.networkService.postRequest(API.galleryLogout).subscribe((response) => resolve(response))
    })
  }
}
