import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router'
import { StorageService } from './storage.service'
import { CONSTANTS } from '../constants'

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(
    private _snackBar: MatSnackBar,
    private router: Router,
    private storageService: StorageService
  ) { }

  public openSnackBar(message: string) {
    this._snackBar.open(message, undefined, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 4000
    })
  }

  public navigateToLogin() {
    this.storageService.deleteData(CONSTANTS.storageKeyData)
    this.router.navigateByUrl(`login`)
  }
}
