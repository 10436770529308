import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core'
import { BrowserModule, DomSanitizer } from '@angular/platform-browser'
import { registerLocaleData } from '@angular/common'
import localeDe from '@angular/common/locales/de'
registerLocaleData(localeDe, 'de-DE')

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'

import { MyErrorHandler } from './helper/MyErrorHandler'

// Interceptors
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { HttpConfigInterceptor } from './networking/interceptor/httpConfig'

// Material
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule, MatIconRegistry } from '@angular/material/icon'
import { MatSnackBarModule } from '@angular/material/snack-bar'

// Components
import { GalleryComponent } from './ui/gallery/gallery.component'
import { LoginComponent } from './ui/login/login.component'
import { GalleryEntryComponent } from './ui/components/gallery-entry/gallery-entry.component'
import { ToggleIconButtonComponent } from './ui/components/toggle-icon-button/toggle-icon-button.component'
import { MilestoneComponent } from './ui/components/milestone/milestone.component'

@NgModule(
  { 
    declarations: [
      AppComponent,
      GalleryComponent,
      LoginComponent,
      GalleryEntryComponent,
      ToggleIconButtonComponent,
      MilestoneComponent
    ],
    bootstrap: [AppComponent], 
    imports: [
      BrowserModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      // Material
      ReactiveFormsModule,
      MatButtonModule,
      MatInputModule,
      FormsModule,
      MatFormFieldModule,
      MatIconModule,
      MatSnackBarModule], providers: [
      { provide: LOCALE_ID, useValue: "de-DE" },
      { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
      { provide: ErrorHandler, useClass: MyErrorHandler },
      provideHttpClient(withInterceptorsFromDi())
    ] 
  }
)
export class AppModule {
  constructor(
    iconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon(
      'milestones',
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/milestones.svg')
    )
    iconRegistry.addSvgIcon(
      'image',
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/image.svg')
    )
    iconRegistry.addSvgIcon(
      'logout',
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/logout.svg')
    )
  }
}
