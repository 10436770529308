import { HttpErrorResponse } from "@angular/common/http"
import { ErrorHandler, Injectable } from "@angular/core"
import { HelperService } from "./services/helper.service"

@Injectable()
export class MyErrorHandler implements ErrorHandler {

    constructor(private helperService: HelperService) {}

    handleError(error: any): void {
        if (error instanceof HttpErrorResponse) {
            if (error.status == 401)
                this.helperService.openSnackBar('Authorisierungsfehler, Eingaben überprüfen')
        }
    }
}
