{
  "name": "neo-gallery",
  "version": "1.1.3",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.0.1",
    "@angular/cdk": "^19.0.1",
    "@angular/cli": "^19.0.2",
    "@angular/common": "^19.0.1",
    "@angular/compiler": "^19.0.1",
    "@angular/compiler-cli": "^19.0.1",
    "@angular/core": "^19.0.1",
    "@angular/forms": "^19.0.1",
    "@angular/material": "^19.0.1",
    "@angular/platform-browser": "^19.0.1",
    "@angular/platform-browser-dynamic": "^19.0.1",
    "@angular/router": "^19.0.1",
    "rxjs": "~7.8.0",
    "uuid": "^11.0.3",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.2",
    "@types/jasmine": "~5.1.5",
    "@types/uuid": "^10.0.0",
    "typescript": "5.6.3"
  }
}
