import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  // Call this method to save data to local storage
  saveData(data: any, storageKey: string) {
    localStorage.setItem(storageKey, JSON.stringify(data));
  }

  // Call this method to retrieve data from local storage
  loadData(storageKey: string) {
    const data = localStorage.getItem(storageKey)
    return data ? JSON.parse(data) : null
  }

  deleteData(storageKey: string) {
    localStorage.removeItem(storageKey)
  }

  deleteAll() {
    localStorage.clear()
  }
}
