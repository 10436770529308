import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { map } from 'rxjs/operators'

import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class NetworkingService {

  constructor(
    private http: HttpClient
  ) { }

  public getRequest(apiEndpoint: string, header?: HttpHeaders, params?: HttpParams) {
    return this.http.get(
      `${environment.url}/${environment.backendVersion}/${apiEndpoint}`,
      {
        headers: header ? header : {},
        params: params ? params : {}
      }
    )
  }

  public postRequest(apiEndpoint: string, payload?: any, header?: HttpHeaders) {
    return this.http.post<any>(
      `${environment.url}/${environment.backendVersion}/${apiEndpoint}`,
      payload,
      {
        headers: header ? header : {}
      }
    ).pipe(map(data =>
      data))
  }
}
