<div class="top-bar">
    <app-toggle-icon-button
        class="logout clickable"
        [icon]="'logout'"
        [label]="(sharedDataService.isHandset$ | async) ? undefined : 'Logout'"
        (click)="logout()">
    </app-toggle-icon-button>
    <ng-container *ngIf="sharedDataService.isHandset$ | async">
        <app-toggle-icon-button
            [icon]="'milestones'"
            [active]="state == 'milestones'"
            (click)="state = 'milestones'">
        </app-toggle-icon-button>
        <app-toggle-icon-button
            [icon]="'image'"
            [active]="state == 'gallery'"
            (click)="state = 'gallery'">
        </app-toggle-icon-button>
    </ng-container>
</div>

<div
    class="gallery"
    (scroll)="checkScroll()"
    [ngClass]="{'mobile': sharedDataService.isHandset$ | async}">
    <div
        class="milestones"
        *ngIf="!(sharedDataService.isHandset$ | async) || state == 'milestones'">
        <h2>Meilensteine</h2>
        <img class="bear" src="./assets/images/bear.png" alt="">
        <app-milestone
            #milestone
            *ngFor="let milestone of milestones"
            [milestone]="milestone">
        </app-milestone>
    </div>

    <div
        class="gallery-entries"
        *ngIf="!(sharedDataService.isHandset$ | async) || state == 'gallery'">
        <h2>Galerie</h2>
        <app-gallery-entry
            #galleryEntry
            *ngFor="let entry of gallery"
            [entry]="entry">
        </app-gallery-entry>
    </div>

</div>
